<template>
  <div class="latesttravel">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="dynamic_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">
          最新游记
        </div>
      </div>
      <div class="hide">
      <div class="travels-box">
        <el-row :gutter="20">
          <el-col :span="6" v-for="item in result" :key="item">
            <router-link :to="{ path: '/latestdata', query: { id: item.id } }">
              <div class="travels-item">
                <img
                  :src="`/jeecg-boot/${item.theLocal}`"
                  class="travels-image"
                />
                <div class="travels-in">
                  <div class="travels-user">
                    <img src="@/assets/image/user/avatar.jpg" class="head" />
                    <span class="username">{{ item.username }}</span>
                  </div>
                  <p class="travels-desc">{{ item.theInfo }}</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      </div>

      <div class="dispy">
      <div class="travels-box">
        <el-row :gutter="20">
          <el-col :span="24" v-for="item in result" :key="item">
            <router-link :to="{ path: '/latestdata', query: { id: item.id } }">
              <div class="travels-item">
                <img
                  :src="`/jeecg-boot/${item.theLocal}`"
                  class="travels-image"
                />
                <div class="travels-in">
                  <div class="travels-user">
                    <img src="@/assets/image/user/avatar.jpg" class="head" />
                    <span class="username">{{ item.username }}</span>
                  </div>
                  <p class="travels-desc">{{ item.theInfo }}</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      </div>
      <Pagi
        :total="pagi.total"
        :current="pagi.current"
        :sizes="pagi.size"
        @pagicurrent="togglePagi"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import BannerImage from "@/components/main/BannerImage";
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadData();
  },
  components: {
    Breadcrumb,
    Header,
    Headers,
    Footer,
    BannerImage,
  },
  data() {
    return {
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      crumbdata: [{ name: "最新游记", url: "/latesttravel" }],
    };
  },
  methods: {
    loadData(value) {
      getAction("/note/tourNote/listAllNote", { pageNo: value }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.travels-box {
  padding: 0 18px;
  min-height: 500px;
  
  .travels-item {
    border: solid 1px #dbdbdb;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
    }
    .travels-image {
      width: 100%;
      height: 200px;
    }
    .travels-in {
      position: relative;
      padding: 0 25px 15px;
      .travels-user {
        height: 24px;
        .head {
          width: 50px;
          border-radius: 50%;
          border: 1px solid #fff;
          position: absolute;
          top: -33px;
        }
        .username {
          color: #999;
          padding-left: 56px;
        }
      }
      .travels-desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 38px;
      }
    }
  }
}
.el-col-24 {
    margin-bottom: 20px;
}
</style>
